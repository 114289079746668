import React, { useState } from "react";
import "./Login.css"; // Используем те же стили, что и для логина
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom";
import {
  auth,
  createUserWithEmailAndPassword,
  updateProfile,
} from "./firebase";
import { useStateValue } from "./StateProvider";

function Register() {
  const dispatch = useStateValue()[1];
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const register = (e) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((result) => {
        // Пользователь создан, теперь обновляем его профиль
        updateProfile(result.user, {
          displayName: name,
        })
          .then(() => {
            dispatch({
              type: "SET_USER",
              user: {
                name,
                email,
                uid: result.user.uid,
              },
            });
            // Профиль успешно обновлен!
            history.push("/"); // Перенаправление на главную страницу
          })
          .catch((error) => {
            // Произошла ошибка при обновлении профиля
            alert(error.message);
          });
      })
      .catch((error) => {
        alert(error.message);
      });
  };

  return (
    <div className="login">
      <Link to="/">
        <img
          className="login__logo"
          src="/images/goalogo2.jpg"
          alt="Goa.Live Logo"
        />
      </Link>
      <div className="login__container">
        <h1>Create Account</h1>

        <form>
          <h5>Your Name</h5>
          <input type="text" value={name} onChange={handleNameChange} />
          <h5>E-mail</h5>
          <input type="text" value={email} onChange={handleEmailChange} />
          <h5>Password</h5>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />

          <button
            type="submit"
            onClick={register}
            className="login__registerButton"
          >
            Create your Goa.Live Account
          </button>
        </form>
      </div>
    </div>
  );
}

export default Register;
