import React, { useState, useEffect } from "react";
import "./Header.css";
import SearchIcon from "@mui/icons-material/Search";
import { ShoppingBasket } from "@mui/icons-material";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useStateValue } from "./StateProvider";
import { useHistory } from "react-router-dom";
import { signOut } from "./firebase";
import MenuIcon from "@mui/icons-material/Menu"; // Импорт иконки меню

function Header() {
  const [{ basket, user }, dispatch] = useStateValue();
  const [activeLink, setActiveLink] = useState(null);
  const history = useHistory();

  const handleActiveLink = (e) => {
    setActiveLink(e.target.id);
  };

  const handleAuthentication = () => {
    if (user) {
      signOut()
        .then(() => {
          dispatch({
            type: "SET_USER",
            user: null,
          });
          history.push("/"); // Optional: Redirect to homepage on sign out
        })
        .catch((error) => {
          console.error("Sign Out Error", error);
        });
    }
  };

  // Состояние для открытия/закрытия подменю
  const [openMenu, setOpenMenu] = useState(null); // Добавили состояние для подменю
  const [menuOpen, setMenuOpen] = useState(false); // Добавленное состояние для меню

  // Функция для переключения состояния меню
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  // Функция для открытия/закрытия подменю
  const handleSubmenuToggle = (menu) => {
    if (openMenu === menu) {
      setOpenMenu(null); // Если меню уже открыто, то закрываем его
    } else {
      setOpenMenu(menu); // Иначе открываем
    }
  };

  const [searchActive, setSearchActive] = useState(false);
  const handleSearchClick = () => {
    setSearchActive(!searchActive);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 600);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Закрыть поле поиска при клике вне его области (необязательно)
  useEffect(() => {
    function handleOutsideClick(event) {
      if (searchActive && !event.target.closest(".header__search")) {
        setSearchActive(false);
      }
      if (
        menuOpen &&
        !event.target.closest(".header__menuIcon") &&
        !event.target.closest(".header__mobileNav")
      ) {
        setMenuOpen(false);
      }
    }
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [searchActive, menuOpen]);

  const shouldHideLogoAndLogin = searchActive && isMobile;

  return (
    <div className="header">
      <Link to="/" className={shouldHideLogoAndLogin ? "mobile-hidden" : ""}>
        <img className="header_logo" src="/images/goalogo2.jpg" alt="" />
      </Link>

      <div className={`header__search ${searchActive ? "active" : ""}`}>
        <input className="header__searchInput" type="text" />
        <SearchIcon
          className="header__searchIcon"
          onClick={handleSearchClick}
        />
      </div>
      <MenuIcon className="header__menuIcon" onClick={handleMenuToggle} />
      <div className="header__menu">
        <div
          id="events"
          className={
            activeLink === "events" ? "header__option active" : "header__option"
          }
          onClick={handleActiveLink}
        >
          Events
        </div>
        <div
          id="marketplace"
          className={
            activeLink === "marketplace"
              ? "header__option active"
              : "header__option"
          }
          onClick={handleActiveLink}
        >
          Marketplace
        </div>
        <div
          id="hotels"
          className={
            activeLink === "hotels" ? "header__option active" : "header__option"
          }
          onClick={handleActiveLink}
        >
          Hotels
        </div>
        <div
          id="tourism"
          className={
            activeLink === "tourism"
              ? "header__option active"
              : "header__option"
          }
          onClick={handleActiveLink}
        >
          Tourism
        </div>
        <div
          id="news"
          className={
            activeLink === "news" ? "header__option active" : "header__option"
          }
          onClick={handleActiveLink}
        >
          News
        </div>
        <div
          id="forum"
          className={
            activeLink === "forum" ? "header__option active" : "header__option"
          }
          onClick={handleActiveLink}
        >
          Forum
        </div>
      </div>

      <Link to={!user && "/login"}>
        <div
          className={`header__login ${
            shouldHideLogoAndLogin ? "mobile-hidden" : ""
          }`}
        >
          <div onClick={handleAuthentication} className="header__login">
            <span className="header__optionLineOne">
              Hi {user ? user.name : "Guest"}
            </span>
            <span className="header__optionLineTwo">
              {user ? "Sign Out" : "Sign In"}
            </span>
          </div>
        </div>
      </Link>

      <Link to="/checkout">
        <div className="header__optionBasket">
          <ShoppingBasket />
          <span className="header__optionLineTwo header__basketCount">
            {basket?.length}
          </span>
        </div>
      </Link>

      {/* Мобильное меню */}
      {menuOpen && (
        <div className="header__mobileNav">
          <div
            className="header__option"
            onClick={() => handleSubmenuToggle("events")}
          >
            Events {openMenu === "events" ? "▲" : "▼"}
          </div>
          {openMenu === "events" && (
            <div className="header__submenu">
              <div className="header__option">Parties</div>
              <div className="header__option">Festivals</div>
              <div className="header__option">Concerts</div>
              <div className="header__option">Local Gatherings</div>
            </div>
          )}

          <div
            className="header__option"
            onClick={() => handleSubmenuToggle("marketplace")}
          >
            Marketplace {openMenu === "marketplace" ? "▲" : "▼"}
          </div>
          {openMenu === "marketplace" && (
            <div className="header__submenu">
              <div className="header__option">Goods for Sale</div>
              <div className="header__option">Services</div>
            </div>
          )}

          <div
            className="header__option"
            onClick={() => handleSubmenuToggle("hotels")}
          >
            Hotels {openMenu === "hotels" ? "▲" : "▼"}
          </div>
          {openMenu === "hotels" && (
            <div className="header__submenu">
              <div className="header__option">Rooms</div>
              <div className="header__option">Apartments</div>
              <div className="header__option">Villas</div>
              <div className="header__option">Transfers</div>
            </div>
          )}

          <div
            className="header__option"
            onClick={() => handleSubmenuToggle("tourism")}
          >
            Tourism {openMenu === "tourism" ? "▲" : "▼"}
          </div>
          {openMenu === "tourism" && (
            <div className="header__submenu">
              <div className="header__option">Beaches</div>
              <div className="header__option">Tours</div>
              <div className="header__option">Activities</div>
              <div className="header__option">Food</div>
            </div>
          )}

          <div
            className="header__option"
            onClick={() => handleSubmenuToggle("news")}
          >
            News {openMenu === "news" ? "▲" : "▼"}
          </div>
          {openMenu === "news" && (
            <div className="header__submenu">
              <div className="header__option">Society</div>
              <div className="header__option">Real Estate</div>
              <div className="header__option">Technology</div>
              <div className="header__option">Automotive</div>
            </div>
          )}

          <div
            className="header__option"
            onClick={() => handleSubmenuToggle("forum")}
          >
            Forum {openMenu === "forum" ? "▲" : "▼"}
          </div>
        </div>
      )}
    </div>
  );
}

export default Header;
