import { db } from "./firebase.js"; // Убедитесь, что путь до firebase.js соответствует вашей структуре проекта
import { doc, setDoc } from "firebase/firestore";

const updateBasketInFirestore = async (userId, basket) => {
  if (!userId) return;

  const userDocRef = doc(db, "users", userId);
  try {
    await setDoc(userDocRef, { basket }, { merge: true });
    console.log("Basket updated in Firestore");
  } catch (error) {
    console.error("Error updating basket in Firestore: ", error);
  }
};

export const initialState = {
  basket: [],
  user: null,
};
// Функция для подсчета общей суммы корзины
export const getBasketTotal = (basket) =>
  basket?.reduce((amount, item) => item.price + amount, 0);

const reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_BASKET":
      return {
        ...state,
        basket: action.basket,
      };
    case "ADD_TO_BASKET":
      // Здесь мы обновляем корзину и сразу же обновляем ее в Firestore
      const newBasketAdd = [...state.basket, action.item];
      updateBasketInFirestore(state.user?.uid, newBasketAdd);
      return {
        ...state,
        basket: newBasketAdd,
      };
    case "REMOVE_FROM_BASKET":
      // Ищем индекс удаляемого элемента
      const index = state.basket.findIndex(
        (basketItem) => basketItem.id === action.id
      );
      let newBasketRemove = [...state.basket];

      if (index >= 0) {
        newBasketRemove.splice(index, 1); // Удаляем элемент из корзины
      } else {
        console.warn(
          `Cant remove product (id: ${action.id}) as its not in basket!`
        );
      }
      // Обновляем корзину в Firestore после удаления элемента
      updateBasketInFirestore(state.user?.uid, newBasketRemove);
      return {
        ...state,
        basket: newBasketRemove,
      };
    case "CLEAN_BASKET":
      return {
        ...state,
        basket: [], // Сбрасываем состояние корзины
      };
    default:
      return state;
  }
};
export default reducer;
