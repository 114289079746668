// Payments.js
import React, { useState, useEffect } from "react";
import "./Payment.css";
import { useStateValue } from "./StateProvider";
import { db } from "./firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

const Payments = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [addressData, setAddressData] = useState({
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    phone: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [{ user }] = useStateValue(); // Предполагаем, что у вас есть user в состоянии

  useEffect(() => {
    if (user) {
      setLoading(true);
      const userRef = doc(db, "users", user.uid);
      getDoc(userRef)
        .then((docSnap) => {
          setLoading(false);
          if (docSnap.exists()) {
            setAddressData(docSnap.data().address || addressData);
          }
        })
        .catch((error) => {
          setLoading(false);
          setError("Failed to fetch address");
        });
    }
  }, [user]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAddressData({
      ...addressData,
      [name]: value,
    });
  };

  const handleSaveAndPay = async (event) => {
    event.preventDefault();
    if (!user) {
      setError("You must be logged in to continue");
      return;
    }

    setLoading(true);
    setError("");
    setSuccess("");

    try {
      const userRef = doc(db, "users", user.uid);

      // Обновление адреса в документе пользователя
      await setDoc(
        userRef,
        {
          address: addressData,
        },
        { merge: true }
      );

      setSuccess("Address saved successfully! Please proceed to payment.");

      // Здесь начните процесс оплаты через Stripe
      // Stripe integration code goes here.

      // После успешной оплаты: setLoading(false); setSuccess('Payment successful!');
    } catch (error) {
      setError("Failed to save address. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="payments">
      <h2>Delivery Address</h2>
      {error && <div className="error-message">{error}</div>}
      {success && <div className="success-message">{success}</div>}
      <form onSubmit={handleSaveAndPay}>
        <div className="input-group">
          <label>Address Line 1:</label>
          <input
            type="text"
            name="addressLine1"
            value={addressData.addressLine1}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-group">
          <label>Address Line 2 (Optional):</label>
          <input
            type="text"
            name="addressLine2"
            value={addressData.addressLine2}
            onChange={handleChange}
          />
        </div>
        <div className="input-group">
          <label>City:</label>
          <input
            type="text"
            name="city"
            value={addressData.city}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="state">State (Optional):</label>
          <input
            type="text"
            id="state"
            name="state"
            value={addressData.state}
            onChange={handleChange}
          />
        </div>
        <div className="input-group">
          <label>ZIP / Postal Code:</label>
          <input
            type="text"
            name="zip"
            value={addressData.zip}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-group">
          <label>Country:</label>
          <input
            type="text"
            name="country"
            value={addressData.country}
            onChange={handleChange}
            required
          />
        </div>
        <div className="input-group">
          <label htmlFor="phone">Phone:</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={addressData.phone}
            onChange={handleChange}
            required
          />
        </div>
      </form>
      {/* Payment section - Payment method */}
      <div className="payment__section">
        <div className="payment__title">
          <h3>Card Details</h3>
        </div>
        <div className="payment__details">
          {/* Stripe magic will go */}
          <form>
            <div className="StripeElement">
              <CardElement
                options={{
                  hidePostalCode: true,
                  style: { base: { fontSize: "16px" } },
                }}
              />
            </div>
          </form>
        </div>
      </div>
      <button type="submit" disabled={loading}>
        {loading ? "Processing..." : "Make Payment"}
      </button>
    </div>
  );
};

export default Payments;
