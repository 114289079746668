import React from "react";
import "./Home.css";
import Product from "./Product";
function Home() {
  return (
    <div className="home">
      <div className="home__container">
        {/* Продукты поверх фонового изображения */}
        <div className="home__row">
          <Product
            id="1"
            title="MANTRA FEST"
            text="Dip dive in a magic of MAHA MANTRA. Buy ticket!"
            image="/images/mantra.jpg"
            price={500}
            rating={10}
          />
          <Product
            id="2"
            title="VAGATOR DANCEFLOOR ADVENTURES"
            text="Digital Distortion at the helm again with the next part outfit. Buy
            ticket!."
            image="/images/vagator.jpg"
            price={1000}
            rating={8}
          />
          <Product
            id="3"
            title="SWARA ENGINE 9.0"
            text="Dropping beats and raising vibes. Buy ticket!"
            image="/images/swara.jpg"
            price={200}
            rating={5}
          />
        </div>

        <div className="home__row">
          <Product
            id="4"
            title="DJUMA SOUND SYSTEM"
            text="IZIKI @AFTERLYF along with MAGNETIZE ENT brings you an night of 📢
            epic proportions. Buy ticket!"
            image="/images/juma.jpg"
            price={500}
            rating={10}
          />
          <Product
            id="5"
            title="QUEER PARTY"
            text="Venue : Saltamontes, Anjuna. Buy ticket!"
            image="/images/queer.jpg"
            price={900}
            rating={6}
          />
          <Product
            id="6"
            title="LATIN NIGHT"
            text="Digital Distortion at the helm again. Buy ticket!"
            image="/images/latin.jpg"
            price={500}
            rating={10}
          />
          <Product
            id="7"
            title="PUNK ROCK"
            text="Digital Distortion at the helm again. Buy ticket!"
            image="/images/pank.jpg"
            price={500}
            rating={10}
          />
        </div>
        <div className="home__row">
          <Product
            id="8"
            title="WHITE PARTY"
            text="Digital Distortion at the helm again with the next part outfit as it
            presents Vagator Dancefloor Adventures. Buy ticket!"
            image="/images/tech.jpg"
            price={2000}
            rating={10}
          />
        </div>
        {/* Здесь может быть ваш следующий блок */}
      </div>
      {/* <img
        className="home__row2"
        src="https://wallpapers.com/images/hd/hawaii-beach-foam-o1dyi7tkfm8hhzib.jpg"
      /> */}
    </div>
  );
}

export default Home;
