import React, { useState } from "react";
import "./Login.css";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { auth, signInWithEmailAndPassword } from "./firebase";
//import { useHistory, Link } from "react-router-dom";

function Login() {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const signIn = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((authUser) => {
        if (authUser) {
          history.push("/"); // Редирект на главную страницу
        }
      })
      .catch((error) => alert(error.message));
  };

  return (
    <div className="login">
      <Link to="/">
        <img
          className="login__logo"
          src="/images/goalogo2.jpg"
          alt="Goa.Live Logo"
        />
      </Link>

      <div className="login__container">
        <h1>Sign-in</h1>

        <form>
          <h5>E-mail</h5>
          <input type="text" value={email} onChange={handleEmailChange} />
          <h5>Password</h5>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />

          <button
            type="submit"
            onClick={signIn}
            className="login__signInButton"
          >
            Sign In
          </button>
        </form>

        <p>By signing-in you agree to the Goa.Live Conditions of Use & Sale.</p>
        <Link
          to="/register"
          className="login__registerButton"
          style={{
            textDecoration: "none",
            display: "inline-block",
            textAlign: "center",
          }}
        >
          Create your Goa.Live Account
        </Link>
      </div>
    </div>
  );
}

export default Login;
