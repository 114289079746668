import React from "react";
import "./Product.css";
import { useStateValue } from "./StateProvider";

function Product({ id, title, text, image, price, rating }) {
  const [{ basket }, dispatch] = useStateValue();
  console.log("this is the basket >>>", basket);
  const addToBasket = () => {
    //dispatch the item to dataLayer
    dispatch({
      type: "ADD_TO_BASKET",
      item: {
        id: id,
        title: title,
        text: text,
        image: image,
        rating: rating,
        price: price,
      },
    });
  };
  return (
    <div className="product">
      <div className="product__info">
        <p>
          <p>
            <b>{title}</b>
          </p>
          {text}
        </p>
      </div>

      <img src={image} alt="" />

      {/* <div className="product__rating">
        {Array(rating)
          .fill()
          .map((_, i) => (
            <p>⭐️</p>
          ))}
      </div> */}
      <p className="product__price">
        <strong>{price}</strong>
        <strong> ₹</strong>
      </p>
      <button onClick={addToBasket}>Add to basket</button>
    </div>
  );
}

export default Product;
