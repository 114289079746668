import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
} from "firebase/auth";
import { signOut as firebaseSignOut } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBon4eEahEMD3_1qhOrY6mg2mdAyb85MjI",
  authDomain: "goa-live-dd3e6.firebaseapp.com",
  projectId: "goa-live-dd3e6",
  storageBucket: "goa-live-dd3e6.appspot.com",
  messagingSenderId: "853705441532",
  appId: "1:853705441532:web:65173a143170e8b4f39779",
  measurementId: "G-6MDEC54MYL",
};
// Инициализация Firebase
const app = initializeApp(firebaseConfig);
//const db = app.firestore();
// Получение аутентификации
const auth = getAuth(app);
// Initialize Firestore
const db = getFirestore(app);

// Экспорт функций аутентификации
export {
  auth,
  db,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  updateProfile,
};
export const signOut = () => {
  const auth = getAuth();
  return firebaseSignOut(auth);
};
